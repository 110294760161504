<div class="header-container">
  <div>
    <ng-container *ngIf="claimList">
      <nz-spin [nzSpinning]="claimList?.loading">
        <ng-container
          *ngIf="
            claimList?.claims && claimList?.claims?.length! > 1;
            else elseClaim
          "
        >
          <nz-select
            [(ngModel)]="selectedClaim"
            nzBorderless
            class="product-dropdown"
            (ngModelChange)="updateSelected($event)"
          >
            <nz-option
              *ngFor="let claim of claimList?.claims"
              [nzValue]="claim.value"
              [nzLabel]="claim.name"
            ></nz-option>
          </nz-select>
        </ng-container>
        <ng-template #elseClaim>
          <div class="single-claim-cnt">
            <h2 class="single-claim" *ngFor="let claim of claimList?.claims">
              {{ claim.name }}
            </h2>
          </div>
        </ng-template>
      </nz-spin>
    </ng-container>
  </div>
  <div class="user-menu">
    <div class="usr-container">
      <span>Welcome</span>
      <a nz-dropdown [nzDropdownMenu]="usrMenu">
        <p>{{ username }}</p>
        <i nz-icon nzType="down"></i>
      </a>
      <nz-dropdown-menu #usrMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable style="min-width: 200px">
          <li nz-menu-item (click)="showModal()" class="p-1">
            <i nz-icon nzType="lock"></i
            ><span class="pl-1">Change password</span>
          </li>
          <li nz-menu-item (click)="logout()" nzDanger class="p-1">
            <i nz-icon nzType="logout"></i> <span class="pl-1">Logout</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</div>
<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Change password"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <app-password-form
      [loading]="loading"
      (formValues)="changePassword($event)"
    ></app-password-form
  ></ng-container>
</nz-modal>
