// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'Kyber Optimize',
  appVersion: require('../../package.json').version + '-dev',
  authConfig: {
    client_id: 'KyberSpreadsheet_Web', // KyberSpreadsheet_Web
    grant_type: 'password',
    scope: 'KyberSpreadsheetAPI', // KyberSpreadsheetAPI
  },
  urlConfig: {
    authUrl: 'https://identityapi.dev.optimize.kyber.ai',
    userUrl: 'https://usersapi.dev.optimize.kyber.ai/api',
    apiUrl: 'https://spreadsheetapi.dev.optimize.kyber.ai',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
