<nz-layout class="layout-container">
  <nz-sider
    nzWidth="265px"
    nzTheme="dark"
    nzCollapsible
    nzBreakpoint="md"
    [nzCollapsedWidth]="0"
  >
    <app-navigation></app-navigation>
  </nz-sider>
  <nz-layout class="inner-layout">
    <nz-header>
      <app-header
        [claimList]="claims | async"
        [selectedClaim]="selected | async"
      ></app-header>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer
      ><div>
        Sources: Kyber Claims Data. US revenue actuals and consensus estimates
        sourced from Visible Alpha
      </div></nz-footer
    >
  </nz-layout>
</nz-layout>
