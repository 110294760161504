export class GetClaims {
  static readonly type = '[Claims] GetClaims';
}

export class UpdateSelectedClaim {
  static readonly type = '[Claims] UpdateSelected';
  constructor(public readonly payload: string) {}
}

export class ClearSelectedClaim {
  static readonly type = '[Claims] ClearSelected';
}
