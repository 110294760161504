import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetClaims } from 'src/app/shared/state/claims/claims.actions';
import { ClaimsStateModel } from 'src/app/shared/state/claims/claims.model';
import { ClaimsState } from 'src/app/shared/state/claims/claims.state';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {

  appName = environment.appName;
  year = new Date().getFullYear();
  
  @Select((state: { claims: ClaimsStateModel }) => state.claims)
  claims!: Observable<ClaimsStateModel>;

  @Select(ClaimsState.getSelectedClaim) selected!: Observable<string>;
  
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new GetClaims());
  }
}
