<div class="sider-menu">
  <div class="logo">
    <img src="assets/logo.svg" alt="Kyber Logo" />
    <nz-divider></nz-divider>
  </div>

  <ul nz-menu nzMode="inline" nzTheme="dark">
    <ul>
      <li nz-menu-group nzTitle="Menu">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/', 'summary']">
              <i nz-icon nzType="table" nzTheme="outline"></i>
              Summary View
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/', 'product']">
              <i nz-icon nzType="line-chart" nzTheme="outline"></i>
              Product View
            </a>
          </li>
<!--           <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/', 'market']">
              <i nz-icon nzType="stock"></i>
              Sandbox
            </a>
          </li> -->
        </ul>
      </li>
    </ul>
  </ul>
</div>
