import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  errCodes = [401, 403];

  constructor(
    private router: Router,
    private authService: AuthService,
    private zone: NgZone
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      tap((err) => {
        if (err instanceof HttpErrorResponse) {
          if (this.errCodes.some((code: number) => code === err.status)) {
            this.authService.signOut();
            console.log('un');
            this.zone.run(() => this.router.navigate(['auth/login']));
          }
        }
      })
    );
  }
}
