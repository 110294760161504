import { Component, Input, OnInit } from '@angular/core';
import {
  Router
} from '@angular/router';
import { Store } from '@ngxs/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SessionStorageService } from 'src/app/auth/services/session-storage.service';
import { ClearSelectedClaim, UpdateSelectedClaim } from 'src/app/shared/state/claims/claims.actions';
import { ClaimsStateModel } from 'src/app/shared/state/claims/claims.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username: string | null = '';
  isVisible: boolean = false;
  loading: boolean = false;

  @Input() selectedClaim: string | null = null;
  @Input() claimList: ClaimsStateModel | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: SessionStorageService,
    private notification: NzNotificationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.username = this.storageService.getUser();
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['auth/login']);
    this.store.dispatch(new ClearSelectedClaim());
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  updateSelected(evt: string) {
    if (this.router.url.includes('/product')) {
      this.router.navigate(['/summary']);
    }

    this.store.dispatch(new UpdateSelectedClaim(evt));
  }

  changePassword(evt: any) {
    if (evt) {
      this.loading = true;
      this.authService
        .changePassword(evt)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.loading = false;
            if (res.status === 204 || res.status === 200) {
              this.handleCancel();
              this.notification.create(
                'success',
                'Password change',
                'Password changed successfully',
                { nzDuration: 4000 }
              );
            }
          },
          (err) => {
            this.loading = false;
          }
        );
    }
  }

}
