import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import { SessionStorageService } from 'src/app/auth/services/session-storage.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import {
  ClearSelectedClaim, GetClaims,
  UpdateSelectedClaim
} from './claims.actions';
import { ClaimsStateModel, ClaimsTypes, RolesDto } from './claims.model';

@State<ClaimsStateModel>({
  name: 'claims',
  defaults: {
    claims: [],
    selected: null,
    loading: false,
  },
})
@Injectable()
export class ClaimsState {
  @Selector()
  static getClaims(state: ClaimsStateModel) {
    return state.claims ?? null;
  }

  @Selector()
  static getSelectedClaim(state: ClaimsStateModel) {
    return state.selected ?? null;
  }

  constructor(
    private settingsService: SettingsService,
    private storageService: SessionStorageService
  ) {}

  @Action(GetClaims, { cancelUncompleted: true })
  getClaims(store: StateContext<ClaimsStateModel>) {
    store.patchState({ claims: [], loading: true });

    return this.settingsService.getClaimsTypes().pipe(
      tap((roles: RolesDto) => {
        const tokenData = this.storageService.decodeToken();
        const userRoles = roles.rolesDtos.filter((role: ClaimsTypes) => {
          return Object.keys(tokenData).find(
            (key: string) => role.value === key
          );
        });

        store.patchState({
          claims: userRoles,
          selected: userRoles[0].value,
          loading: false,
        });
      }),
      catchError((err: HttpErrorResponse) => {
        store.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(UpdateSelectedClaim)
  updateSelected(
    store: StateContext<ClaimsStateModel>,
    action: UpdateSelectedClaim
  ) {
    return store.patchState({ selected: action.payload });
  }

  @Action(ClearSelectedClaim)
  clearSelected(store: StateContext<ClaimsStateModel>) {
    return store.patchState({ selected: null });
  }
}
