import {
  HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { SessionStorageService } from 'src/app/auth/services/session-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public storageService: SessionStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.storageService.getToken();
    const tokenType = this.storageService.getTokenType();

    if (!!token) {
      request = this.addToken(request, token, tokenType);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string, tokenType: string | null) {
    return request.clone({
      setHeaders: {
        Authorization: `${tokenType} ${token}`,
      },
    });
  }
}
