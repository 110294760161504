import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthResponse } from '../interfaces/auth-reponse';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  cleanStorage(): void {
    sessionStorage.clear();
  }

  storeData(data: AuthResponse, user: string): void {
    Object.keys(data).forEach((element: string) => {
      const val = data[element as keyof AuthResponse];
      sessionStorage.setItem(element, val.toString());
    });
    sessionStorage.setItem('username', user);
  }

  getToken(): string | null {
    const tokenInfo = sessionStorage.getItem('access_token');
    return tokenInfo ? tokenInfo : null;
  }

  getTokenType(): string | null {
    const tokenType = sessionStorage.getItem('token_type');
    return tokenType ? tokenType : null;
  }

  getUser(): string | null {
    const userInfo = sessionStorage.getItem('username');
    return userInfo ? userInfo : null;
  }

  decodeToken() {
    const token = sessionStorage.getItem('access_token');
    const jwtHelper = new JwtHelperService();
    return !!token ? jwtHelper.decodeToken(token) : null; 
  }

  getAssignableCLaims(claims: any) {
    const claimsfromToken = claims.filter((claim: any) => {
      return Object.entries(this.decodeToken()).find((key) => claim.value === key[0] && key[1] === '1')
    });

    return claimsfromToken;
  }
}
